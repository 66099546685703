import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

function pageMeta(obj) {
	return {
		metaInfo: Object.assign({}, {title: '登录',keywords: '',description: ''}, obj)
	}
};

const routes = [{
		path: '/',
		name: 'login',
		component: () => import('@/views/login.vue'),
		meta: pageMeta({title: '登录'}),
	},
	{
		path: '/wxlogin/login',
		name: 'wxlogin/login',
		component: () => import('@/views/wxlogin/login.vue'),
		meta: pageMeta({title: '微信登录'}),
	},
	{
		path: '/wxlogin/bind',
		name: 'wxlogin/bind',
		component: () => import('@/views/wxlogin/bind.vue'),
		meta: pageMeta({title: '微信绑定'}),
	},
	{
		path: '/index',
		name: 'index',
		component: () => import('@/views/index.vue'),
		meta: pageMeta({title: '集合页'}),
	},
	{
		path: '/topic/index',
		name: 'topic',
		component: () => import('@/views/topic/index.vue'),
		meta: pageMeta({title: '5G沉浸式数字体验馆'}),
	}
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
