import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'lib-flexible'
import './plugins/element.js'

import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css'


import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

let titleObj = {
    'login': '登录',
    'index': '系统选择',
    'permit/userlist': '用户管理',
    'permit/rolelist': '角色管理',
    'permit/modulelist': '模块管理',
    'permit/systemlist': '系统管理',
    'permit/contSystem': '可控系统',
    'dept/deptlist': '本系统',
    'dept/deptddlist': '浙政钉',
    'set/user': '个人资料',
    'set/base': '基本配置',
    'wxlogin/login': '微信登陆',
    'wxlogin/bind': '微信绑定',
    'topic': '5G沉浸式数字体验馆',
}
router.beforeEach((to, from, next) => {
    if (to.name === 'topic') {
        document.title = titleObj[to.name]
    } else {
        document.title = '统一用户中心-' + titleObj[to.name];
    }
    next();
});

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')




 
