import Vue from 'vue'
import { Button, Form,Tooltip, FormItem, Input,Slider,MessageBox, Message, Loading, Notification } from 'element-ui'

// 按钮
Vue.use(Button)
Vue.use(Slider)
Vue.use(Tooltip)
//表单
Vue.use(Form)
Vue.use(FormItem)

//输入框
Vue.use(Input)

Vue.use(Loading)
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;